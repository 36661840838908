 
html,
body,
#root {
  height: 100%;
}
.mind-map-container{
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
}

.react-flow__node-mindmap {
  background: white;
}
.react-flow__handle.target {
  top: 50%;
  pointer-events: none;
  opacity: 0;
}
.react-flow__handle.source {
  top: 0;
  left: 0;
  transform: none;
  background: none;
  color: #353535;
  height: 1px;
  width: 100%;
  border: none;
 
}

.border{
  border: 1px solid #ededed;
}




/* code,
pre {
	font-family: Söhne Mono, Monaco, Andale Mono, Ubuntu Mono, monospace !important
}

code[class=language-plaintext] {
	white-space: pre-line
}

 
.hljs-comment {
	color: hsla(0, 0%, 100%, .5) !important;
}

.hljs-meta {
	color: hsla(0, 0%, 100%, .6) !important;
}

.hljs-built_in,
.hljs-class .hljs-title {
	color: #e9950c !important;
}

.hljs-doctag,
.hljs-formula,
.hljs-keyword,
.hljs-literal {
	color: #2e95d3 !important;
}

.hljs-addition,
.hljs-attribute,
.hljs-meta-string,
.hljs-regexp,
.hljs-string {
	color: #00a67d !important;
}

.hljs-attr,
.hljs-number,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
	color: #df3079 !important;
} */

/* .hljs-bullet,
.hljs-link,
.hljs-selector-id,
.hljs-symbol,
.hljs-title {
	color: #f22c3d
} */


/* antd */

.ant-btn-primary{
  background-color: #07c160;
  border-color: #07c160;
  color: white;
}