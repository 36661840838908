@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  font-variation-settings: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Ant Design Tree */
.ant-tree-node-selected {
  background-color: rgba(52,53,65,1) !important;   
  color: #fff !important;  
}
 

/* tailwind  */
.h-screen {
  height: calc(100vh - 65px);
}


.font-logo{
  font-family: 'Baloo Bhai 2', cursive;
}